import logo from './logo.svg';
import grein from './images/grein-landing-lg.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <img src={grein} alt="Welcome to the Drying Grein - the flexible air dryer, ideal for range cookers." width="1080"/>
      
        {/* <a
          className="App-link"
          // href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Empty Link
        </a> */}
      </header>
    </div>
  );
}

export default App;
